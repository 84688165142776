<div class="content" role="main">
<!--  <div *ngIf="this.locale === 'nl' && yesNo !='no'" class="alert alert-warning">Vanaf 29/10/2021 is de enige eis die wij hebben het dragen van een mondkapje tijdens het bezoek in onze WED2B winkels.</div>-->
<!--  <div *ngIf="this.locale === 'fr' && yesNo !='no'" class="alert alert-warning">A partir du 29/10/2021, la seule exigence que nous avons est de porter un masque buccal lors de la visite de nos boutiques WED2B.</div>-->
  <div class="header-container" [className]="this.existing === 'no' ? 'new-customer' : 'existing-customer'">
    <div class="card-container">
      <div *ngFor="let language of languages">
        <a  class="card" [ngClass]="(this.locale == language.locale)?'active':''" rel="noopener" href="{{language.href}}">
          <img width="40" alt="{{language.name}}" src="assets/images/flags/{{language.image}}"/>
          <span>{{language.name}}</span>
          <svg class="material-icons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/></svg>
        </a>
      </div>
    </div>
    <div class="intro-container">
        <h1 i18n>Welcome, have you visited us before?</h1>
        <nav>
          <mat-radio-group aria-label="Select an option" [(ngModel)]="yesNo">
            <mat-radio-button id="yes" value="yes" (click)="toggleForm('yes', true)"><span i18n>Yes</span></mat-radio-button>
            <mat-radio-button id="no" value="no" (click)="toggleForm('no', false)"><span i18n>No</span></mat-radio-button>
          </mat-radio-group>
        </nav>
      </div>
  </div>
  <div class="form-container" [ngClass]="this.existing !== null ? 'active' : ''">
    <app-new-customer *ngIf="showNewCustomer" (existing)="back($event)" (submissionComplete)="completed($event)"></app-new-customer>
    <app-existing-customer *ngIf="showExistingCustomer" (submissionComplete)="completed($event)" (toggleForm)="toggleForm('no', false)"></app-existing-customer>
  </div>
  <app-thank-you *ngIf="submissionComplete" (submissionComplete)="completed($event)"></app-thank-you>
  <!-- Footer -->
  <footer></footer>
</div>
<router-outlet></router-outlet>
