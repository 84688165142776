<h2><span i18n>Welcome Form</span><button class="back-btn" mat-stroked-button color="primary" (click)="back()" i18n>< Back</button></h2>
<form id="new-customer-form" [formGroup]="newCustomer" (ngSubmit)="onSubmit()" autocomplete="off">
  <p class="no-margin" i18n>Please fill out the form below before you find your dream dress!</p>
  <small i18n>Mandatory fields</small><span class="required-field star-sign">*</span>
  <label *ngIf="false">
    <span i18n>Today's Date</span>
    <input id="date" name="date" type="date" formControlName="date" [readOnly]="true" required>
  </label>
  <label>
    <span i18n>How did you hear about us?</span><span class="required-field" [ngClass]="{'input-invalid': newCustomer.controls.referralId.invalid, 'input-valid': newCustomer.controls.referralId.valid}">*</span>
    <div id="referralId">
      <mat-form-field appearance="fill">
        <mat-label></mat-label>
        <mat-select formControlName="referralId" panelClass="countryCode">
          <div *ngFor="let option of referrals">
            <mat-option [value]="option.id" [disabled]="option.disable" *ngIf="!option.disable">
              {{option.name}}
            </mat-option>
          </div>
        </mat-select>
      </mat-form-field>
    </div>
<!--    <select class="referral" formControlName="referralId">-->
<!--      <option [hidden]="option.disable" [disabled]="option.disable" *ngFor="let option of referrals" [ngValue]="option.id" >{{option.name}}</option>-->
<!--    </select>-->
  </label>
  <label *ngIf="this.newCustomer.value.referralId === 5">
    <span i18n>Please Specify:</span>
    <textarea id="comments" formControlName="comments" rows="5" cols="40"></textarea>
  </label>
  <div id="visit-reason-group" formGroupName="visitReason">
    <label>
      <span i18n>What have you come to look for today?</span><span class="required-field" [ngClass]="{'input-invalid': newCustomer.controls.visitReason.invalid, 'input-valid': newCustomer.controls.visitReason.valid}">*</span>
    </label>
    <mat-radio-group formControlName="reason" aria-label="Select an option">
      <mat-radio-button id="wedding-dresses" value="wedding-dresses" ><span i18n>Wedding Dresses</span></mat-radio-button>
      <mat-radio-button id="bridesmaids-dresses" value="bridesmaid-dresses" ><span i18n>Bridesmaids/Flower Girl Dresses</span></mat-radio-button>
      <mat-radio-button id="accessories" value="accessories" ><span i18n>Accessories</span></mat-radio-button>
      <mat-radio-button id="wedding-dress-collection" value="wedding-dress-collection" ><span i18n>Wedding Dress Click and Collect Order</span></mat-radio-button>
      <mat-radio-button id="holy-communion" value="holy-communion" ><span i18n>Holy Communion Dresses</span></mat-radio-button>
    </mat-radio-group>
  </div>
  <label>
    <span i18n>First Name</span><span class="required-field" [ngClass]="{'input-invalid': newCustomer.controls.firstName.invalid, 'input-valid': newCustomer.controls.firstName.valid}">*</span>
    <input id="firstName" name="firstName" type="text" formControlName="firstName" required>
  </label>
  <label>
    <span i18n>Last Name</span><span class="required-field" [ngClass]="{'input-invalid': newCustomer.controls.lastName.invalid, 'input-valid': newCustomer.controls.lastName.valid}">*</span>
    <input id="lastName" name="lastName" type="text" formControlName="lastName" required>
  </label>
  <label style="position:relative;">
    <span i18n>Wedding Date</span>
    <mat-form-field class="wedding-date-mat" appearance="fill">
      <input id="weddingDate" name="weddingDate" matInput formControlName="weddingDate" [min]="minDate()" (click)="picker.open()" [matDatepicker]="picker" readonly>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker touchUi #picker></mat-datepicker>
    </mat-form-field>
  </label>
  <label>
    <span i18n>How many in your entourage today, including yourself?</span><span class="required-field" [ngClass]="{'input-invalid': newCustomer.controls.entourage.invalid, 'input-valid': newCustomer.controls.entourage.valid}">*</span>
    <div style="position:relative;">
      <input id="entourage" type="number" min="1" max="10" formControlName="entourage" autocomplete="off" readonly required (focus)="onBlur($event)">
      <div class="btn-increment-decrement-wrapper">
        <span (click)="increment()" class="material-icons">add</span>
        <span (click)="decrement()" class="material-icons">remove</span>
      </div>
    </div>
  </label>
  <label>
    <span i18n>Email</span><span class="required-field" [ngClass]="{'input-invalid': newCustomer.controls.email.invalid, 'input-valid': newCustomer.controls.email.valid}">*</span>
    <input id="email" name="email" type="email" formControlName="email" [email]="true" required>
  </label>
  <div id="address" formGroupName="address">
    <label>
      <span i18n>Select Country</span><span class="required-field" [ngClass]="{'input-invalid': newCustomer.controls.address.invalid, 'input-valid': newCustomer.controls.address.valid}">*</span>
      <div id="countryCode">
        <mat-form-field appearance="fill" panelClass="countryCode">
          <mat-label></mat-label>
          <mat-select formControlName="countryCode" (selectionChange)="onCountryChange($event.value)">
            <div *ngFor="let country of countryList let i = index">
              <mat-option [value]="country.short_code">
                {{country.country_name}}
              </mat-option>
            </div>
          </mat-select>
        </mat-form-field>
      </div>
    </label>
    <div class="address-list-container">
      <label>
        <span i18n>Type post/zip or street</span><span class="required-field" [ngClass]="{'input-invalid': newCustomer.controls.address.invalid, 'input-valid': newCustomer.controls.address.valid}">*</span>
        <input id="query" name="query" type="text" formControlName="query" (focus)="onFocus($event)" (keyup)="onKey($event)" (blur)="onBlur($event)" required autocomplete="postcode">
      </label>
      <ul [hidden]="hideAddressList">
        <li *ngFor="let address of addressList" (mousedown)="$event.preventDefault()" (click)="onSelect(address)">{{address.labels.toString()}}{{address.count > 1 ? ' ('+address.count+'More) >' : ''}}</li>
      </ul>
    </div>
    <div *ngIf="this.selectedAddress">
      <label>
        <span i18n>Line Address 1</span><span class="required-field" [ngClass]="{'input-invalid': newCustomer.controls.address.invalid, 'input-valid': newCustomer.controls.address.valid}">*</span>
        <input id="lineAddress1" name="lineAddress1" type="text" formControlName="lineAddress1" [required]="true" [readonly]="true">
      </label>
      <label>
        <span i18n>Line Address 2</span>
        <input id="lineAddress2" name="lineAddress2" type="text" formControlName="lineAddress2" [readonly]="true">
      </label>
      <label>
        <span i18n>Line Address 3</span>
        <input id="lineAddress3" name="lineAddress3" type="text" formControlName="lineAddress3" [readonly]="true">
      </label>
      <label>
        <span i18n>Town</span>
        <input id="town" name="town" type="text" formControlName="town" [readonly]="true">
      </label>
      <label>
        <span i18n>County</span>
        <input id="county" name="county" type="text" formControlName="county" [readonly]="true">
      </label>
      <label>
        <span i18n>Postcode</span><span class="required-field" [ngClass]="{'input-invalid': newCustomer.controls.address.invalid, 'input-valid': newCustomer.controls.address.valid}">*</span>
        <input id="postcode" name="postcode" type="text" formControlName="postcode" [required]="true" [readonly]="true">
      </label>
    </div>
  </div>
  <label>
    <span i18n>Telephone</span><span class="telephone-validation-mark" [ngClass]="{'input-untouched': newCustomer.controls.telephone.untouched, 'input-invalid': newCustomer.controls.telephone.invalid, 'input-valid': newCustomer.controls.telephone.valid}">*</span>
<!--    <input id="telephone" name="telephone" type="tel" formControlName="telephone">-->
  </label>
  <ngx-intl-tel-input
    [inputId]="'telephone'" [cssClass]="'telephone'" [preferredCountries]="preferredCountries"
    [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="false"
    [selectFirstCountry]="false" [selectedCountryISO]="telephoneCountrySelected"
    [maxLength]="'15'" [phoneValidation]="true"
    [numberFormat]="PhoneNumberFormat.National" name="telephone" formControlName="telephone">
  </ngx-intl-tel-input>
  <div id="marketing-permissions" formGroupName="marketingPermissions">
    <label i18n id="label-info">Be in the know…</label>
    <p id="marketing-permissions-info" class="no-margin" i18n>We’d love to keep in touch to tell you about our new arrivals in store, wedding tips, competitions and inspiration ahead of your big day.</p>
    <section class="marketing-permission-section">
      <mat-checkbox id="allowAllMarketing" class="allowAllMarketing" formControlName="allowAll" value="allowAll" ngDefaultControl [checked]="allMarketingPermissionsComplete" (change)="setAll($event.checked)"><span i18n>Yes please, I’d love to stay in touch</span></mat-checkbox>
      <ul>
        <li [hidden]="true"><mat-checkbox formControlName="sms" value="sms" (ngModelChange)="updateAllComplete()"><span i18n>SMS</span></mat-checkbox></li>
        <li [hidden]="true"><mat-checkbox formControlName="phone" value="phone" (ngModelChange)="updateAllComplete()"><span i18n>Phone</span></mat-checkbox></li>
        <li [hidden]="true"><mat-checkbox formControlName="email" value="email" (ngModelChange)="updateAllComplete()"><span i18n>Email</span></mat-checkbox></li>
      </ul>
    </section>
    <p id="terms-and-conditions-info" class="no-margin">
      <span  i18n>You can change your mind at any time by clicking the unsubscribe link in the footer of any email, or contacting us at info@wed2b.com. We will treat your information with respect. For more information about our privacy practices, please view our</span>&nbsp;
      <button (click)="openDialog()" type="button" i18n>Privacy Policy</button>
    </p>
  </div>
  <div>
    <button class="button" type="submit" [disabled]="!newCustomer.valid || formSubmitted">{{submitButtonText}}</button>
  </div>
  <div [hidden]="!error" class="submission-error"><p><samp>{{errorMessage}}</samp></p></div>
</form>
<pre>
<!--  <code>{{this.newCustomer.value | json}}</code>-->
</pre>
