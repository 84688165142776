import {Component, LOCALE_ID, Inject, isDevMode} from '@angular/core';
import {FormBuilder} from '@angular/forms';
export type ExistingType = 'yes' | 'no' | null;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent {
  title = 'welcome-form';
  submissionComplete: boolean | false | undefined;
  existing: string | null = null;
  showBack = false;
  yesNo = null;
  locale: string;
  languages: any = [
    {name: 'English', locale: 'en-GB', href: '/', image: 'gb.svg'},
    {name: 'Nederlands', locale: 'nl', href: '/nl/', image: 'nl.svg'},
    {name: 'Deutsch', locale: 'de', href: '/de/', image: 'de.svg'},
    {name: 'Français', locale: 'fr', href: '/fr/', image: 'fr.svg'}
  ];
  constructor(@Inject(LOCALE_ID) protected localeId: string) {
    // @ts-ignore
    this.locale = this.localeId;
    if (isDevMode()) {
      console.log('👋 Development!');
    } else {
      console.log('💪 Production!');
    }
  }

  // tslint:disable-next-line:typedef
  get showExistingCustomer() {
    return this.existing === 'yes';
  }

  // tslint:disable-next-line:typedef
  get showNewCustomer() {
    return this.existing === 'no';
  }

  // tslint:disable-next-line:typedef
  toggleForm(type: ExistingType, showBack: boolean ) {
    this.showBack = showBack;
    this.showBack = !this.showBack;
    this.existing = type;
    this.submissionComplete = false;
  }

  // tslint:disable-next-line:typedef
  back(value: string | null) {
    this.existing = value;
    this.yesNo = null;
    this.showBack = false;
  }

  // tslint:disable-next-line:typedef
  completed(value: boolean) {
    this.submissionComplete = value;
    this.existing = null;
    this.yesNo = null;
  }
}
