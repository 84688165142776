import { Inject, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatDialogModule, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatOptionModule } from '@angular/material/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ExistingCustomerComponent } from './existing-customer/existing-customer.component';
import { NewCustomerComponent } from './new-customer/new-customer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
export const MY_FORMATS = {
  useUtc: true,
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@NgModule({
  declarations: [
    AppComponent,
    ExistingCustomerComponent,
    NewCustomerComponent,
    PrivacyPolicyComponent,
    ThankYouComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    HttpClientModule,
    MatDialogModule,
    MatRadioModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatOptionModule,
    MatSelectModule,
    MatExpansionModule,
    NgxIntlTelInputModule
  ],
  entryComponents: [
    PrivacyPolicyComponent
  ],
  providers: [
    { provide: MatDialogModule, useValue: {}},
    { provide: MAT_DIALOG_DATA, useValue: {}},
    { provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: { color: 'accent' }},
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { color: 'accent' }},
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: MY_FORMATS},
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]},
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ],
  bootstrap: [AppComponent]
})
export class AppModule {
  locale: any;
  // tslint:disable-next-line:variable-name
  constructor(private _adapter: DateAdapter<any>, @Inject(LOCALE_ID) protected localeId: string) {
    this.locale = localeId;
    if (this.locale !== 'en-US') {
      this._adapter.setLocale(this.locale);
    }
  }
}
