import { Injectable } from '@angular/core';
import {EMPTY, Observable, throwError, TimeoutError} from 'rxjs';
import { map, catchError, retry } from 'rxjs/operators';
import {HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import { environment } from '../environments/environment';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*'
  })
};

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  private baseApiUrl = environment.addressApiUrl;  // URL to web api
  private apiKey = environment.addressApiKey;
  private navApiUrl = environment.navApiUrl;
  private localApiUrl = environment.localApiUrl;
  public objData: object | undefined;
  constructor(private http: HttpClient) {}
  // tslint:disable-next-line:typedef
  getCountryList() {
    return this.http.get<any>(`${this.baseApiUrl}countries?key=${this.apiKey}`);
  }

  // tslint:disable-next-line:typedef
  findAddress(country: string, query: string, id?: string) {
    // tslint:disable-next-line:max-line-length
    return this.http.get<any>(`${this.baseApiUrl}find?key=${this.apiKey}&country=${country}&query=${query}&id=${id}`);
  }

  // tslint:disable-next-line:typedef
  retrieveAddress(country: string, id: string) {
    // tslint:disable-next-line:max-line-length
    return this.http.get<any>(`${this.baseApiUrl}retrieve?key=${this.apiKey}&country=${country}&id=${id}`);
  }

  // tslint:disable-next-line:typedef
  setObjData(obj: any) {
    this.objData =  obj;
  }

  // tslint:disable-next-line:typedef
  getObjData() {
    return this.objData;
  }

  // tslint:disable-next-line:typedef
  sendData(obj: any): Observable<any>
  {
    return this.http.post(`${this.localApiUrl}`, obj, httpOptions)
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(() => {
          // if (err.status === 500) {
          //   return Observable.throw(new Error(err.status));
          // }
          // if (err instanceof TimeoutError) {
          //   return throwError('Timeout Exception');
          // }
          // return {error: true, message: err};
          return  EMPTY;
          console.warn('INT ERR:');
        })
      );
  }
}
