// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  navApiUrl: 'http://213.218.198.138:8047/website/WS/WED2B/Codeunit/WedToBServices',
  localApiUrl: 'https://dev.wf.wed2b.com/soap/api/',
  addressApiUrl: 'https://api.craftyclicks.co.uk/address/1.1/',
  addressApiKey: '3bafd-b8774-10898-02b28'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
