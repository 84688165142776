<h3>
  <span i18n>PRIVACY POLICY</span>
  <button style="float: right" (click)="onClose()" tabindex="-1" i18n>OK</button>
</h3>
<div class="mat-dialog-content mat-typography">
  <div>
    <br>
    <h4 class="strong" i18n>Introduction</h4>
    <p i18n>Wed2B Limited, part of the PRG Group (“we”, “us” or “our”) respects your privacy and we are committed to protecting your personal data. This privacy notice will inform you as to how we look after your personal data when you visit our website (regardless of where you visit it from) and tell you about your privacy rights and how the law protects you.</p>
    <p i18n>Please also use the Glossary to understand the meaning of some of the terms used in this privacy notice.</p>
  </div>
  <ol>
    <li>
      <h4 class="strong" i18n>Important Information and who we are</h4>
      <p class="strong" i18n>Purpose of this privacy notice</p>
      <p i18n>This privacy notice aims to give you information on how we collect and processes your personal data through your use of this website, including any data you may provide through this website when you sign up to our newsletter.</p>
      <p i18n>It is important that you read this privacy notice together with any other privacy notice or fair processing notice we may provide on specific occasions when we are collecting or processing personal data about you so that you are fully aware of how and why we are using your data. This privacy notice supplements the other notices and is not intended to override them.</p>
      <p class="strong" i18n>Controller – who we are</p>
      <p i18n>The data controller of our site (www.wed2b.com) is Wed2B Ltd of 6 Precedent Drive, Rooksley, Milton Keynes MK13 8PE. We have appointed a Data Protection Officer who is responsible for overseeing questions in relation to this privacy notice. If you have any questions about this privacy notice, including any requests to exercise your legal rights, please contact info@wed2b.com or please write to The Data Protection Officer, 6 Precedent Drive, Rooksley, Milton Keynes, MK13 8PE.</p>
      <p i18n>You have the right to make a complaint at any time to the Information Commissioner's Office (ICO), the UK supervisory authority for data protection issues (www.ico.org.uk). We would, however, appreciate the chance to deal with your concerns before you approach the ICO so please contact us in the first instance.</p>
      <p class="strong" i18n>Changes to the privacy notice and your duty to inform us of changes</p>
      <p i18n>This version was last updated on 9th March 2021. It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us.</p>
      <p class="strong" i18n>Third-party links</p>
      <p i18n>This website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage you to read the privacy notice of every website you visit.</p>
    </li>
    <li>
      <h4 class="strong" i18n>The data we collect about you</h4>
      <p i18n>Personal data, or personal information, means any information about an individual from which that person can be identified.</p>
      <p i18n>We may collect, use, store and transfer different kinds of personal data about you which we have grouped together follows:</p>
        <p class="no-margin"><span class="strong" i18n>Identity Data</span>&nbsp;<span i18n>includes first name last name.</span></p>
        <p class="no-margin"><span class="strong" i18n>Contact Data</span>&nbsp;<span i18n>includes delivery address, email address and telephone numbers.</span></p>
        <p class="no-margin"><span class="strong" i18n>Financial Data</span>&nbsp;<span i18n>includes bank account and payment card details.</span></p>
        <p class="no-margin"><span class="strong" i18n>Technical Data</span>&nbsp;<span i18n>includes internet protocol (IP) address browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform and other technology on the devices you use to access this website.</span></p>
        <p class="no-margin"><span class="strong" i18n>Transaction Data</span>&nbsp;<span i18n>includes details about payments to and from you and other details of products and services you have purchased from us.</span></p>
        <p class="no-margin"><span class="strong" i18n>Usage Data</span>&nbsp;<span i18n>includes information about how you use our website, products and services.</span></p>
        <p class="no-margin"><span class="strong" i18n>Marketing and Communications Data</span>&nbsp;<span i18n>includes your preferences in receiving marketing from us and our third parties and your communication preferences.</span></p>
      <p i18n>We also collect and use Aggregated Data such as statistical or demographic data for internal purposes.</p>
      <p i18n>We do not collect any Special Categories of Personal Data about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health and genetic and biometric data). Nor do we collect any information about criminal convictions and offences.</p>
      <p class="strong" i18n>If you fail to provide personal data</p>
      <p i18n>Where we need to collect personal data by law, or under the terms of a contract we have with you and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you (for example, to provide you with an answer to an enquiry). In this case, we may have to cancel a service you have with us but we will notify you if this is the case at the time.</p>
    </li>
    <li>
      <h4 class="strong" i18n>How is your personal data collected</h4>
      <p i18n>We use different methods to collect data from and about you including through:</p>
      <span class="strong" i18n>Direct interactions.</span>&nbsp;<span i18n>You may give us your Identity Data, Contact Data, and Financial Data by filling in forms or by corresponding with us by phone, email, post, fax, at our local stores or otherwise. This includes personal data you provide when you:</span>
      <ul>
        <li i18n>apply for our products or services;</li>
        <li i18n>enter your details in store;</li>
        <li i18n>submit an enquiry;</li>
        <li i18n>enter a competition, promotion or survey;</li>
        <li i18n>request our newsletter to be sent to you.</li>
      </ul>
      <span class="strong" i18n>Automated technologies or interactions.</span>&nbsp;<span i18n>As you interact with our website, we may automatically collect Technical Data about your equipment, browsing actions and patterns. We collect this personal data by using cookies, and other similar technologies. Please visit https://www.wed2b.com/enable-cookies for further details.</span>
    </li>
    <li>
      <h4 class="strong" i18n>How we use your personal data</h4>
      <p class="no-margin" i18n>We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:</p>
      <ul>
        <li i18n>where we need to perform the contract we are about to enter into or have entered into with you;</li>
        <li i18n>where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests;</li>
        <li i18n>where we need to comply with a legal or regulatory obligation.</li>
      </ul>
      <p class="no-margin" i18n>Generally we do not rely on consent as a legal basis for processing your personal data other than in relation to sending third party direct marketing communications to you via email or text message. You have the right to withdraw consent to marketing at any time by contacting us.</p>
      <p class="strong" i18n>Purposes for which we will use your personal data</p>
      <p i18n>We have set out below, in a table format, a description of all the ways we plan to use your personal data, and which of the legal bases we rely on to do so. We have also identified what our legitimate interests are where appropriate.</p>
      <p i18n>Note that we may process your personal data for more than one lawful ground depending on the specific purpose for which we are using your data. Please %link if you need details about the specific legal ground we are relying on to process your personal data where more than one ground has been set out in the table below.</p>
      <h3 class="strong" i18n>Purpose / Activity</h3>
      <div class="purpose-activity">
        <table>
          <tbody>
            <tr>
              <th class="strong" i18n>Purpose / Activity</th>
              <th class="strong" i18n>Type of Data</th>
              <th class="strong" i18n>Lawful basis for processing including basis of legitimate interest</th>
            </tr>
            <tr>
              <td i18n>To process and deliver your order including managing payments.</td>
              <td>
                <span class="block" i18n>(a) Identity</span>
                <span class="block" i18n>(b) Contact</span>
                <span class="block" i18n>(c) Financial</span>
                <span class="block" i18n>(d) Marketing and Communications</span>
                <span class="block" i18n>(e) Transaction</span>
              </td>
              <td>
                <span class="block" i18n>(a) Performance of a contract with you.</span>
                <span class="block" i18n>(b) Necessary for our legitimate interests.</span>
              </td>
            </tr>
            <tr>
              <td>
                <span class="block" i18n>To manage our relationship with you which will include:</span>
                <span class="block" i18n>(a) Notifying you about changes to our terms or privacy policy</span>
                <span class="block" i18n>(b) Asking you to leave a review or take a survey</span>
              </td>
              <td>
                <span class="block" i18n>(a) Identity</span>
                <span class="block" i18n>(b) Contact</span>
                <span class="block" i18n>(c) Marketing and Communications</span>
              </td>
              <td>
                <span class="block" i18n>(a) Performance of a contract with you</span>
                <span class="block" i18n>(b) Necessary to comply with a legal obligation</span>
                <span class="block" i18n>(c) Necessary for our legitimate interests (to keep our records updated and to study how customers use our products/services)</span>
              </td>
            </tr>
            <tr>
              <td i18n>To enable you to partake in a prize draw, competition or complete a survey</td>
              <td>
                <span class="block" i18n>(a) Identity</span>
                <span class="block" i18n>(b) Contact</span>
                <span class="block" i18n>(c) Usage</span>
                <span class="block" i18n>(d) Marketing and Communications</span>
              </td>
              <td>
                <span class="block" i18n>(a) Performance of a contract with you</span>
                <span class="block" i18n>(b) Necessary for our legitimate interests (to study how customers use our products/services, to develop them and grow our business)</span>
              </td>
            </tr>
            <tr>
              <td i18n>To respond to your enquiry submitted on the site, or via phone, email, post or otherwise.</td>
              <td>
                <span class="block" i18n>(a) Identity</span>
                <span class="block" i18n>(b) Contact</span>
              </td>
              <td>
                <span class="block" i18n>(a) Performance of a contract with you.</span>
                <span class="block" i18n>(b) Necessary for our legitimate interests (where you have made contact with us prior to entering into a contractual relationship with us.)</span>
              </td>
            </tr>
            <tr>
              <td i18n>To administer and protect our business and this website (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data).</td>
              <td>
                <span class="block" i18n>(a) Identity</span>
                <span class="block" i18n>(b) Contact</span>
                <span class="block" i18n>(c) Technical</span>
              </td>
              <td>
                <span class="block" i18n>(a) Necessary for our legitimate interests (for running our business, provision of administration and IT services, network security, to prevent fraud and in the context of a business reorganisation or group restructuring exercise).</span>
                <span class="block" i18n>(b) Necessary to comply with a legal obligation.</span>
              </td>
            </tr>
            <tr>
              <td i18n>To use data analytics to improve our website, products/services, marketing, customer relationships and experiences.</td>
              <td>
                <span class="block" i18n>(a) Technical</span>
                <span class="block" i18n>(b) Marketing and Communications</span>
              </td>
              <td i18n>Necessary for our legitimate interests (to define types of customers for our products and services, to keep our website updated and relevant, to develop our business and to inform our marketing strategy).</td>
            </tr>
            <tr>
              <td i18n>To make suggestions and recommendations to you about services that may be of interest to you.</td>
              <td>
                <span class="block" i18n>(a) Identity</span>
                <span class="block" i18n>(c) Technical</span>
                <span class="block" i18n>(d) Marketing and Communications</span>
              </td>
              <td i18n>Necessary for our legitimate interests (to develop our products/services and grow our business) which, if you are an individual, will include reliance on the soft opt-in principle (in accordance with Regulation 22 of The Privacy &amp; Electronic Communications (EC Directive) Regulations 2003).</td>
            </tr>
          </tbody>
        </table>
        <div>
          <h4 class="strong" i18n>Marketing</h4>
          <p i18n>We strive to provide you with choices regarding certain personal data uses, particularly around marketing and advertising. We may use your Identity, Contact, Technical and Marketing of Communications Data to form a view on what we think you may want or need, or what may be of interest to you. This is how we decide which services and offers may be relevant for you (we call this marketing).</p>
          <p i18n>You will receive marketing communications from us if you have requested information from us and unless you have opted out of receiving that marketing. You can ask us or third parties to stop sending you marketing messages at any time by contacting us at any time.</p>
          <p i18n>We may also use your information to create anonymous data (whereby you cannot be identified) for market profiling purposes which may be used by us and shared with other companies in The PRG Group or third parties.</p>
          <p i18n>We will not share your information with third parties in order for that third party to provide direct marketing communications to you, unless you have provided your consent for such use. Such third parties will have their own policies relating to the way in which your information may be used.</p>
          <p i18n>In order to make certain services available to you, we may need to share your personal data with some of our service partners. These include IT, delivery, marketing service providers and analytics and search engine providers that assist us in the improvement and optimisation of our website and customer service.</p>
          <p class="strong" i18n>Cookies</p>
          <p i18n>You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of this website may become inaccessible or not function properly. For more information about the cookies we use, please visit https://www.wed2b.com/enable-cookies.</p>

          <p class="strong" i18n>Change of Purpose</p>
          <p i18n>We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose, please contact us.</p>
        </div>
      </div>
    </li>
    <br>
    <li>
      <h4 class="strong" i18n>Disclosures of your personal data</h4>
      <p i18n>We may have to share your personal data with the parties set out below for the purposes set out in the table in paragraph 4 above:</p>
      <ul>
        <li i18n>External Third Parties as set out in the Glossary</li>
        <li i18n>Third parties to whom we may choose to sell, transfer, or merge parts of our business or our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this privacy notice.</li>
      </ul>
      <p i18n>We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not allow our third-party service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.</p>
    </li>
    <li>
      <h4 class="strong" i18n>International transfers</h4>
      <p i18n>We do not transfer your personal data outside the European Economic Area (EEA)</p>
    </li>
    <li>
      <h4 class="strong" i18n>Data Security</h4>
      <p i18n>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality. We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.</p>
    </li>
    <li>
      <h4 class="strong" i18n>Data Retention</h4>
      <p i18n>We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.</p>
      <p i18n>To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.</p>
      <p i18n>Details of retention periods for different aspects of your personal data are available in our retention policy which you can request from us by contacting us.</p>
    </li>
    <li>
      <h4 class="strong" i18n>Your legal rights</h4>
      <p i18n>Under certain circumstances, you have rights under data protection laws in relation to your personal data. Please click on the links below to find out more about these rights:</p>
      <ul>
        <li i18n>Request access to your personal data.</li>
        <li i18n>Request correction of your personal data.</li>
        <li i18n>Request erasure of your personal data.</li>
        <li i18n>Object to processing of your personal data.</li>
        <li i18n>Request restriction of processing your personal data.</li>
        <li i18n>Request transfer of your personal data.</li>
        <li i18n>Right to withdraw consent.</li>
      </ul>
      <p i18n>If you wish to exercise any of the rights set out above, please contact us.</p>
      <p i18n>You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request in these circumstances.</p>
      <p class="strong" i18n>What we may need from you</p>
      <p i18n>We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.</p>
      <p i18n>We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.</p>
    </li>
    <li>
      <h4 class="strong" i18n>Glossary</h4>
      <p class="strong" i18n>LAWFUL BASIS</p>
      <p i18n>Legitimate Interest means the interest of our business in conducting and managing our business to enable us to give you the best service/product and the best and most secure experience. We make sure we consider and balance any potential impact on you (both positive and negative) and your rights before we process your personal data for our legitimate interests. We do not use your personal data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law). You can obtain further information about how we assess our legitimate interests against any potential impact on you in respect of specific activities by contacting us.</p>
      <p i18n>Performance of Contract means processing your data where it is necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract.</p>
      <p i18n>Comply with a legal or regulatory obligation means processing your personal data where it is necessary for compliance with a legal or regulatory obligation that we are subject to.</p>
      <h4 i18n class="strong">THIRD PARTIES</h4>
      <p i18n class="strong">External Third Parties</p>
      <ul>
        <li>
          <p i18n>HM Revenue & Customs, regulators and other authorities based in the United Kingdom who require reporting of processing activities in certain circumstances.</p>
        </li>
      </ul>
      <h4 class="strong" i18n>YOUR LEGAL RIGHTS</h4>
      <p class="strong" i18n>You have the right to:</p>
      <p i18n>Request access to your personal data (commonly known as a "data subject access request"). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.</p>
      <p i18n>Request correction of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.</p>
      <p i18n>Request erasure of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.</p>
      <p i18n>Object to processing of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.</p>
      <p i18n>Request restriction of processing of your personal data. This enables you to ask us to suspend the processing of your personal data in the following scenarios: (a) if you want us to establish the data's accuracy; (b) where our use of the data is unlawful but you do not want us to erase it; (c) where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims; or (d) you have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it. </p>
      <p i18n>Request the transfer of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you. </p>
      <p i18n>Withdraw consent at any time where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent. </p>
    </li>
  </ol>
  <button (click)="onClose()" tabindex="-1" i18n>Close</button>
  <br>
  <br>
</div>
